<template>
  <footer v-if="visible" class="page-footer" :class="footerClass">
    <section class="footer-container">
      <div class="content">
        <div class="consult">
          <div class="item">
            <div class="title">售后专线</div>
            <div class="tel">400-900-1231</div>
          </div>
        </div>

        <div class="info-item">
          <div class="title">权威认证</div>
          <span class="text">浙江省3A信用企业</span>
          <span class="text">快手战略服务商</span>
        </div>
        <div class="info-item">
          <div class="title">联系我们</div>
          <span class="text">
            <a href="mailto:MKBusiness@mockuai.com" class="email"
              >官方合作：MKBusiness@mockuai.com</a
            >
          </span>
          <span class="text">
            <a href="mailto:hr@mockuai.com" class="email"
              >简历投递：hr@mockuai.com</a
            >
          </span>
          <span class="text">总部地址：浙江省杭州市余杭区龙泉路20号</span>
        </div>
        <div class="qrcode">
          <div class="qrcode-item">
            <img
              src="https://mktv-in-cdn.mockuai.com/16239294698829827.png"
              alt=""
              class="icon"
            />
            <img
              src="https://mktv-in-cdn.mockuai.com/16341076251582199.jpg"
              alt=""
              class="code"
            />
          </div>
          <div class="qrcode-item">
            <img
              src="https://mktv-in-cdn.mockuai.com/16239294698759859.png"
              alt=""
              class="icon"
            />
            <img
              src="https://mk-crm-cdn.mockuai.com/home/1723083617476681923.jpg"
              alt=""
              class="code"
            />
          </div>
        </div>
      </div>

      <div class="info">
        <div class="item">
          <span
            >©2015-2022 Mockuai.com
            杭州魔筷科技有限公司（杭州次元岛科技有限公司系魔筷全资子公司）</span
          >
        </div>
        <div class="item">
          <span style="margin-right: 15px">魔筷科技V3.1.2</span>
          <a
            target="__blank"
            href="https://act.mockuai.com/m/3c2c33b405044508397639dc649d13cd.html"
          >
            应用权限
          </a>
          <a target="__blank" href="https://cdn.mockuai.com/tms/7886.html">
            隐私权政策
          </a>
          <a
            target="__blank"
            href="https://mktv-in-cdn.mockuai.com/16024961508525175.png"
          >
            信息系统安全等级保护（三级）
          </a>
          <a
            target="__blank"
            href="https://cdn.mockuai.com/tms/27001zs_edit.pdf"
          >
            ISO27001信息安全管理体系认证
          </a>
          <a target="__blank" href="https://cdn.mockuai.com/tms/47307.pdf">
            网络食品交易管理承诺书
          </a>
          <a
            style="cursor: pointer"
            @click="
              open(
                'https://pc-helper.mockuai.com/?app_type=30&channel_id=1&page_id=15&tag=2'
              )
            "
          >
            SaaS规则中心
          </a>
          <a
            style="cursor: pointer"
            @click="
              open(
                'https://pc-helper.mockuai.com/?app_type=30&channel_id=1&page_id=14&tag=1'
              )
            "
          >
            供应商规则中心
          </a>
          <a
            style="cursor: pointer"
            @click="open('https://www.mockuai.com/intellectual-property')"
          >
            知识产权
          </a>
        </div>
        <div class="item">
          <span style="margin-right: 15px"
            >违法和不良信息及算法推荐专项举报电话：400-900-1231</span
          >
          <span>邮箱：weijinjubao@mockuai.com</span>
        </div>
        <div class="item">
          <span style="margin-right: 15px"
            >增值电信业务经营许可证：浙B2-20180636 -
            <a href="https://beian.miit.gov.cn">浙ICP备15018042号-1</a>
            浙网食A3300100015 新出发浙备字第2020001号
            （浙）网械平台备字【2020】第00014号</span
          >
          <a
            class="police"
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33010602004760"
          >
            <img
              src="https://mktv-in-cdn.mockuai.com/16359274165654253.png"
              alt=""
              class="item_ico"
            />
            浙公网安备 33010602004760号</a
          >
        </div>
        <div class="item"><a class="police" href="https://mktv-in.oss-cn-hangzhou.aliyuncs.com/open_file2023101221115574900018604.pdf">魔筷营业执照</a></div>
      </div>
    </section>
  </footer>
</template>

<script>
export default {
  props: {
    theme: {
      type: String,
      default: "normal",
    },
    type: {
      type: String,
      default: "layout",
    },
  },
  computed: {
    footerClass() {
      return this.theme === "transparent" ? "bg-trans" : "";
    },
    visible() {
      if (this.type === "layout") {
        
        const { withoutLayout, withoutFooter } = this.$route.meta;
        console.log('withoutLayout, withoutFooter', withoutLayout, withoutFooter)
        return !withoutLayout && !withoutFooter;
      } else {
        return true;
      }
    },
  },
  mounted() {
    console.log("footer mounted", this.$route.meta.withoutLayout);
  },
  methods: {
    open(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="less" scoped>
.page-footer {
  box-sizing: border-box;
  // position: fixed;
  // left: 0;
  // bottom: 0;
  width: 100%;
  overflow: hidden;
  background-color: #00092a;
  &.bg-trans {
    background-color: transparent;
  }
  .footer-container {
    margin: 0 auto;
    min-width: 960px;
    color: #fff;
    .content {
      display: flex;
      justify-content: center;
      padding-top: 52px;
      padding-bottom: 46px;
      .consult {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .item {
          .title {
            line-height: 28px;
            margin-bottom: 16px;
            font-size: 20px;
            font-family: PingFangSC-Semibold, PingFang SC;
            font-weight: 600;
          }
          .tel {
            line-height: 35px;
            font-size: 30px;
            font-family: DINCond-Bold, DINCond;
            font-weight: bold;
          }
        }
      }
      .info-item {
        display: flex;
        flex-direction: column;
        margin-right: 60px;
        .title {
          margin-bottom: 31px;
          font-size: 20px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          line-height: 28px;
        }
        .text {
          text-decoration: none;
          color: #fff;
          margin-bottom: 33px;
          font-size: 14px;
          font-family: PingFangSC-Light, PingFang SC;
          font-weight: 300;
          line-height: 20px;
          opacity: 0.8;
          &:last-child {
            margin-bottom: 0;
          }
          a {
            text-decoration: none;
            color: #fff;
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
      .qrcode {
        display: flex;
        .qrcode-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 18px;
          .icon {
            height: 20px;
            object-fit: cover;
          }
          .code {
            width: 104px;
            height: 104px;
            margin-top: 12px;
          }
        }
      }
    }
    .info {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 50px;
      padding-bottom: 50px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -100%;
        width: 10000px;
        height: 1px;
        background-color: #999;
        opacity: 0.3;
      }
      .item {
        font-size: 14px;
        line-height: 30px;
        opacity: 0.8;
        display: flex;
        .police {
          display: flex;
          align-items: center;
        }
        .item_ico {
          width: 20px;
          height: 20px;
          display: block;
          margin-right: 5px;
        }
        span {
          opacity: inherit;
          a {
            color: #fff;
          }
        }
        a {
          margin-right: 15px;
          text-decoration: none;
          color: #fff;
          opacity: inherit;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}
@media screen and(max-width: 1200px) {
  .page-footer {
    overflow: auto;
    .content {
      .consult {
        margin-right: 30px;
      }
      .info-item {
        margin-right: 20px;
      }
    }
  }
}
@media screen and(min-width: 1200px) and(max-width: 1640px) {
  .page-footer {
    .footer-container {
      width: 1440px;
      .content {
        .consult {
          margin-right: 120px;
        }
        .info-item {
          margin-right: 80px;
        }
      }
    }
  }
}
@media screen and (min-width: 1640px) {
  .page-footer {
    .footer-container {
      width: 1440px;
      .content {
        .consult {
          margin-right: 200px;
        }
        .info-item {
          margin-right: 150px;
        }
      }
    }
  }
}
</style>
